import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import './About.css';
import { useTranslation } from 'react-i18next';

export default function About() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLang = localStorage.getItem('language');
    console.log("use Effecting about");
    if (savedLang) {
        i18n.changeLanguage(savedLang);
        document.dir = savedLang === 'ar' ? 'rtl' : 'ltr';
    }
}, [i18n]);

  return (
    <div className='container'>
        <Header />
        <main>
            <div>
                <h1>{t('about.title')}</h1>
                <p className='content-paragraph'>
               {t('about.paragraph1')}
                </p>
                <p className='content-paragraph'>
                {t('about.paragraph2')}
                </p>
            </div>
        </main>
        <Footer />
    </div>
  )
}
