import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAuth, signInWithEmailLink, isSignInWithEmailLink } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase'; // Ensure this imports the Firebase auth instance correctly
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const FinishSignInPage = () => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const firebaseAuth = getAuth();

    useEffect(() => {
        console.log("useEffect running")

        const handleSignInWithEmailLink = async () => {
            // const email = window.localStorage.getItem('emailForSignIn');

            // Prioritize localStorage, fallback to sessionStorage
            const email = window.localStorage.getItem('emailForSignIn') 
            // || window.sessionStorage.getItem('emailForSignIn');

            if (!email) {
                navigate('/'); // Redirect to home if email not found in local storage
                return;
            }

            if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
                try {
                    await signInWithEmailLink(firebaseAuth, email, window.location.href);
                    window.localStorage.removeItem('emailForSignIn');
                    // window.sessionStorage.removeItem('emailForSignIn'); // Clean up sessionStorage as well
                    console.log('Successfully signed in with email link');

                    const user = firebaseAuth.currentUser;
                    if (user) {
                      const idToken = await user.getIdToken();
        
                    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/register`,
                        {}, 
                        { headers: { Authorization: `Bearer ${idToken}` } }
                      );
        
                    }

                    // const productId = searchParams.get('productId');
                    // navigate(productId ? `/product-page/${productId}` : '/'); // Redirect based on productId

                    // navigate('/'); // Redirect to home page or another protected page


                // Add delay before navigation to ensure sign-in process is completed on mobile
                setTimeout(() => {
                    navigate('/'); // Redirect to home page or another protected page
                }, 2000); // Adjust delay as needed


                } catch (error) {
                    console.error('Error signing in with email link:', error);
                    // Handle error, e.g., show an error message to the user
                }
            }
        };

        handleSignInWithEmailLink();

    }, [navigate, searchParams, firebaseAuth]);

    return <div>{t('finishSigninPage.completingSignin')}</div>;
};

export default FinishSignInPage;
