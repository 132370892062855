import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { getAuth, signOut } from 'firebase/auth';
import { useAuth } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faBell, faSignOutAlt, faSignInAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import MenuModal from './MenuModal';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const savedLang = localStorage.getItem('language');
        if (savedLang) {
            i18n.changeLanguage(savedLang);
            document.dir = savedLang === 'ar' ? 'rtl' : 'ltr';
        }
    }, [i18n]);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        document.dir = lang === 'ar' ? 'rtl' : 'ltr';
        localStorage.setItem('language', lang); // Save to local storage
      };

      
    const { user } = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const menuRef = useRef(null);

    const handleLogout = async () => {
        const auth = getAuth();
        try {
            await signOut(auth);
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <header className="header">
            <div className="header-container">
                <div className="logo">
                    <Link to="/">
                    <img 
                     src="/logo.png" 
                     alt="PriceAlertz Logo" 
                     style={{ width: '150px', height: 'auto' }} />
                     </Link>
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    ☰
                </div>
                <nav ref={menuRef} className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                    <Link to="/"> <FontAwesomeIcon icon={faHome} /> {t('header.nav.home')}</Link>
                    <Link to="/about"> <FontAwesomeIcon icon={faInfoCircle} /> {t('header.nav.about')}</Link>
                    <Link to="/notifications"> <FontAwesomeIcon icon={faBell} /> {t('header.nav.priceAlerts')}</Link>
                    <div className="categories-nav-item" onClick={toggleModal}>
                        <FontAwesomeIcon icon={faBars} /> {t('header.nav.categories')}
                    </div>
                    {user ? (
                        <button className="logout-button" onClick={handleLogout}>
                            <FontAwesomeIcon icon={faSignOutAlt} />
                            {t('header.nav.logout')}
                        </button>
                    ) : (
                        <Link to="/sendSignInLink"> <FontAwesomeIcon icon={faSignInAlt} /> {t('header.nav.login')}</Link>
                    )}
                     {i18n.language === 'en' && (
                        <button 
                            onClick={() => changeLanguage('ar')} 
                            className="language-button"
                        >
                            العربية
                        </button>
                    )}
                    {i18n.language === 'ar' && (
                        <button 
                            onClick={() => changeLanguage('en')} 
                            className="language-button"
                        >
                            English
                        </button>
                    )}
                </nav>
            </div>
            {isModalOpen && <MenuModal onClose={toggleModal} />}
        </header>
    );
};

export default Header;