import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faSnapchatGhost, faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    const { t, i18n } = useTranslation();
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-logo">
                    <Link to="/">
                    <img 
                     src="/logo.png" 
                     alt="PriceAlertz Logo" 
                     style={{ width: '150px', height: 'auto' }} />
                     </Link>
                </div> 
  
                <div className="footer-links">
                    <Link to="/privacy">{t('footer.privacyPolicy')}</Link>
                    <Link to="/terms">{t('footer.tos')}</Link>
                    <Link to="/contact">{t('footer.contactUs')}</Link>
                </div>
            </div>
            <div className="footer-bottom">
                &copy; {new Date().getFullYear()} {t('footer.copyright')}
            </div>
        </footer>
    );
};

export default Footer;
