import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const savedLang = localStorage.getItem('language');
        console.log("use Effecting header")
        if (savedLang) {
            i18n.changeLanguage(savedLang);
            document.dir = savedLang === 'ar' ? 'rtl' : 'ltr';
        }
    }, [i18n]);


    return (
        <div>
            <Header />
            <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                <h1>{t('privacyPage.title')}</h1>

                <section>
                    <h2 className='privacy-title'>{t('privacyPage.intro')}</h2>
                    <p className='privacy-parag'>{t('privacyPage.intro.paragraph')}</p>
                </section>

                <section>
                    <h2 className='privacy-title'>{t('privacyPage.title1')}</h2>
                    <h3>{t('privacyPage.title1.heading1.1')}</h3>
                    <ul>
                        <li>
                            <strong>{t('privacyPage.title1.heading1.1.contactInfoTitle')} </strong> 
                            {t('privacyPage.title1.heading1.1.contactInfoParag')}
                        </li>
                        <li>
                            <strong>{t('privacyPage.title1.heading1.1.accInfoTitle')} </strong> 
                            {t('privacyPage.title1.heading1.1.accInfoParag')}
                        </li>
                        <li>
                            <strong>{t('privacyPage.title1.heading1.1.preferencesTitle')} </strong> 
                            {t('privacyPage.title1.heading1.1.preferencesParag')}
                        </li>
                    </ul>

                    <h3>{t('privacyPage.title1.heading1.2')}</h3>
                    <ul>
                        <li>
                            <strong>{t('privacyPage.title1.heading1.2.deviceInfoTitle')} </strong> 
                            {t('privacyPage.title1.heading1.2.deviceInfoParag')}
                        </li>
                        <li>
                            <strong>{t('privacyPage.title1.heading1.2.logDataTitle')} </strong> 
                            {t('privacyPage.title1.heading1.2.LogDataParag')}
                        </li>
                        <li>
                            <strong>{t('privacyPage.title1.heading1.2.cookiesTitle')} </strong> 
                            {t('privacyPage.title1.heading1.2.cookiesParag')}
                        </li>
                    </ul>
                </section>

                <section>
                    <h2 className='privacy-title'>{t('privacyPage.title2')}</h2>
                    <ul>
                        <li>
                            <strong>{t('privacyPage.title2.improveServicesTitle')} </strong> 
                            {t('privacyPage.title2.improveServicesParag')}
                        </li>
                        <li>
                            <strong>{t('privacyPage.title2.personalizeTitle')} </strong> 
                            {t('privacyPage.title2.personalizeParag')}
                        </li>
                        <li>
                            <strong>{t('privacyPage.title2.communicateTitle')} </strong> 
                            {t('privacyPage.title2.communicateParag')}
                        </li>
                        <li>
                            <strong>{t('privacyPage.title2.customerSupportTitle')} </strong> 
                            {t('privacyPage.title2.customerSupportParag')}
                        </li>
                        <li>
                            <strong>{t('privacy.title2.analyzeTitle')} </strong> 
                            {t('privacy.title2.analyzeParag')}
                        </li>
                    </ul>
                </section>

                <section>
                    <h2 className='privacy-title'>{t('privacy.title3')}</h2>
                    <ul>
                        <li>
                            <strong>{t('privacy.title3.serviceProvidersTitle')} </strong> 
                            {t('privacy.title3.serviceProvidersParag')}
                        </li>
                        <li>
                            <strong>{t('privacy.title3.legalReasonsTitle')} </strong> 
                            {t('privacy.title3.legalReasonsParag')}
                        </li>
                        <li>
                            <strong>{t('privacy.title3.businessTransfersTitle')} </strong> 
                            {t('privacy.title3.businessTransfersParag')}
                        </li>
                    </ul>
                </section>

                <section>
                    <h2 className='privacy-title'>{t('privacy.title4')}</h2>
                    <p className='privacy-parag'>{t('privacy.title4.parag')}</p>
                </section>

                <section>
                    <h2 className='privacy-title'>{t('privacy.title5')}</h2>
                    <p className='privacy-parag'>{t('privacy.title5.parag')}</p>
                </section>

                <section>
                    <h2 className='privacy-title'>{t('privacy.title6')}</h2>
                    <p className='privacy-parag'>{t('privacy.title6.parag')}</p>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicyPage;