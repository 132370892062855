import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './LandingPage.css';
import Header from './Header';
import ProductBox from './ProductBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';
import Footer from './Footer';
import { Helmet } from "react-helmet"
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

const LandingPage = () => {
    const { t, i18n } = useTranslation();

    const [products, setProducts] = useState([]);
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [featuredIphone16s, setFeaturedIphone16s] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const fetchProducts = async (page, searchTerm = '') => {
        try {
            const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/main/search`;
            const response = await axios.get(endpoint, {
                params: {
                    page,
                    searchTerm // This will be ignored by the paginated endpoint
                },
            });

            const newProducts = response.data.products;

            const sortedProducts = newProducts.sort((a, b) => {
                const popA = a.popularityScore !== null ? a.popularityScore : 0;
                const popB = b.popularityScore !== null ? b.popularityScore : 0;

                if (popA !== popB) {
                    return popB - popA;
                }

                const priceDiffA = (a.prices.original - a.prices.discounted) || 0;
                const priceDiffB = (b.prices.original - b.prices.discounted) || 0;
                return priceDiffB - priceDiffA;
            });

            if (searchTerm) {
                setFilteredProducts((prevFilteredProducts) => {
                    if (page === 1) {
                        return sortedProducts;
                    } else {
                        return [...prevFilteredProducts, ...sortedProducts];
                    }
                });
            } else {
                setProducts((prevProducts) => {
                    if (page === 1) {
                        return sortedProducts;
                    } else {
                        return [...prevProducts, ...sortedProducts];
                    }
                });
                setFilteredProducts((prevFilteredProducts) => {
                    if (page === 1) {
                        return sortedProducts;
                    } else {
                        return [...prevFilteredProducts, ...sortedProducts];
                    }
                });
            }

            setHasMore(page < response.data.totalPages);
        } catch (error) {
            console.error('Error fetching products:', error);
        }  
    };

    const fetchCategorizedProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/product/categories`);
            setFeaturedProducts(response.data);
            // console.log(featuredProducts, "featued products")
        } catch (error) {
            console.error('Error fetching categorized products:', error);
        }
    };

    const fetchIphone16s = async () => {
      try {
        const searchTerm = "Apple Iphone 16"
        const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/tech/phones/iphone16`;
        const response = await axios.get(endpoint, {
            params: {
                page,
                searchTerm // This will be ignored by the paginated endpoint
            },
        });
          setFeaturedIphone16s(response.data);
          // console.log(featuredProducts, "featued products")
      } catch (error) {
          console.error('Error fetching categorized products:', error);
      }
  };
    
    useEffect(() => {
         fetchCategorizedProducts();
        //  fetchIphone16s();
    }, []);

     useEffect(() => {
        if (page > 1) {
          fetchProducts(page, search);
        }
      }, [page, search]);
   
      const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

        // Debounce the search input
        const debouncedFetchProducts = useCallback(debounce((page, searchTerm) => {
            fetchProducts(page, searchTerm);
        }, 500), []); // Adjust the debounce delay as needed


          useEffect(() => {
            if (search.trim() === '') {
                setPage(1);
                setFilteredProducts([]); // Clear filtered products
                setProducts([]); // Optionally clear all products if needed
            } else {
                setPage(1);
                setHasMore(true);
                debouncedFetchProducts(1, search);
            }
        }, [search, debouncedFetchProducts]);



          const CategorySlider = ({ title, link, data, sliderSettings }) => (
            <div className={`slider-container ${title.toLowerCase()}`}>
              <div className="slider-header">
                <h2 className='category-title'>{title}</h2>
                <Link to={link} className="see-all">{t('landingPage.seeAll')}</Link>
              </div>
              <Slider {...sliderSettings}>
                {data.map(product => (
                  <div key={product._id} className="product slider-item">
                    <ProductBox product={product} />
                  </div>
                ))}
              </Slider>
            </div>
          );


          // const Iphone16Slider = ({ data }) => {
          //   if (!data) return null; // Prevents rendering if there's no data
          
          //   const sliderSettings = {
          //     dots: true,
          //     infinite: true,
          //     speed: 500,
          //     slidesToShow: 4,
          //     slidesToScroll: 1,
          //     responsive: [
          //       {
          //         breakpoint: 1024,
          //         settings: {
          //           slidesToShow: 3,
          //           slidesToScroll: 1,
          //           infinite: true,
          //           dots: true
          //         }
          //       },
          //       {
          //         // breakpoint: 600,
          //         breakpoint: 700,
          //         settings: {
          //           slidesToShow: 2,
          //           slidesToScroll: 1,
          //           initialSlide: 2
          //         }
          //       },
          //       {
          //         breakpoint: 480,
          //         settings: {
          //           slidesToShow: 1,
          //           slidesToScroll: 1
          //         }
          //       }
          //     ]
          //   };

          //   return (
          //     <CategorySlider
          //       title={t('landingPage.featuredIphone16')}
          //       link="/iphone16"
          //       data={data}
          //       sliderSettings={sliderSettings}
          //     />
          //   );
          // };


          const FeaturedProducts = ({ data }) => {
             // Define the screen object with default values
             const screen = data.screen || { tv: [], monitor: [] };

              const sliderSettings = {
              dots: true,
              infinite: true,
              speed: 500,
              slidesToShow: 4,
              slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  // breakpoint: 600,
                  breakpoint: 700,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
            };
          
            return (
              <div className="featured-products">
                   {/* {featuredIphone16s ? (
                  <CategorySlider
                    title={t('landingPage.featuredIphone16')}
                    link="/iphone16s"
                    data={featuredIphone16s}
                    sliderSettings={sliderSettings}
                  />
                ) : null } */}
                {data.skincare && (
                  <CategorySlider
                    title={t('landingPage.featuredSkincare')}
                    link="/skincare"
                    data={data.skincare}
                    sliderSettings={sliderSettings}
                  />
                )}
                {data.makeup && (
                  <CategorySlider
                    title={t('landingPage.featuredMakeup')}
                    link="/makeup"
                    data={data.makeup}
                    sliderSettings={sliderSettings}
                  />
                )}
                 {data.haircare && (
                  <CategorySlider
                    title={t('landingPage.featuredHaircare')}
                    link="/haircare"
                    data={data.haircare}
                    sliderSettings={sliderSettings}
                  />
                )}
                 {data.fragrance && (
                  <CategorySlider
                    title={t('landingPage.featuredFragrances')}
                    link="/fragrances"
                    data={data.fragrance}
                    sliderSettings={sliderSettings}
                  />
                )}
                 {data.supplement && (
                  <CategorySlider
                    title={t('landingPage.featuredSupplements')}
                    link="/supplements"
                    data={data.supplement}
                    sliderSettings={sliderSettings}
                  />
                )}
                 {data.phone && (
                  <CategorySlider
                    title={t('landingPage.featuredPhones')}
                    link="/phones"
                    data={data.phone}
                    sliderSettings={sliderSettings}
                  />
                )}
                 {data.smartwatch && (
                  <CategorySlider
                    title={t('landingPage.featuredSmartwatches')}
                    link="/smartwatches"
                    data={data.smartwatch}
                    sliderSettings={sliderSettings}
                  />
                )}
                     {data.tablet && (
                  <CategorySlider
                    title={t('landingPage.featuredTablets')}
                    link="/tablets"
                    data={data.tablet}
                    sliderSettings={sliderSettings}
                  />
                )}
                     {data.laptop && (
                  <CategorySlider
                    title={t('landingPage.featuredLaptops')}
                    link="/laptops"
                    data={data.laptop}
                    sliderSettings={sliderSettings}
                  />
                )}
                  {screen.tv.length > 0 && (
                  <CategorySlider
                    title={t('landingPage.featuredTvs')}
                    link="/tvs"
                    data={data.screen.tv}
                    sliderSettings={sliderSettings}
                  />
                )}
                  {screen.monitor.length > 0 && (
                  <CategorySlider
                    title={t('landingPage.featuredMonitors')}
                    link="/monitors"
                    data={data.screen.monitor}
                    sliderSettings={sliderSettings}
                  />
                )}
                
              </div>
            );
          };
          

    return (
        <div className="container">
         <Helmet>
         <html lang={i18n.language} />

      <title>{i18n.language === 'ar' ? 'الصفحة الرئيسية | PriceAlertz' : 'Home Page | PriceAlertz'}</title>
      <meta
        name="description"
        content={
          i18n.language === 'ar'
            ? 'ابحث عن منتجاتك المفضلة، قارن الأسعار بين المتاجر، احصل على تاريخ الأسعار، واحصل على تنبيهات الأسعار'
            : 'Search for your favourite products, compare prices across stores, get price history, and get price alerts'
        }
      />
      <meta
        name="keywords"
        content={
          i18n.language === 'ar'
            ? 'أفضل الأسعار، مقارنة الأسعار، تنبيهات الأسعار، هواتف، أجهزة لوحية، آيفون، ساعات ذكية، منتجات سامسونج، لابتوبات، تلفزيونات، شاشات، العناية بالبشرة، مرطبات، مكياج، العناية بالشعر، العطور، المكملات الغذائية'
            : 'Best prices, price comparison, price alerts, phones, tablets, iphones, smartwatches, samsung products, laptops, tvs, monitors, skincare, moisturizers, makeup, haircare, fragrances, supplements'
        }
      />
    </Helmet>


            {/* Header Section */}

           <div className='header-menu-container'>
          <Header />
          {/* <Menu /> */}
          </div>

          <div className="search-bar search-bar-landing-page">
             <input 
                    type="text" 
                    placeholder={t('landingPage.searchbar')} 
                    value={search} 
                    onChange={handleSearchChange} 
                />
            </div>
          {search.trim() !== '' && filteredProducts.length > 0 && (
          <div className="search-products">
              <h2>{t('landingPage.searchResults')}</h2>
              <InfiniteScroll
                  dataLength={filteredProducts.length}
                  next={() => setPage((prevPage) => prevPage + 1)}
                  hasMore={hasMore}
                  loader={<p>{t('general.loading')}</p>}
                  endMessage={<p>{t('general.noMoreProducts')}</p>}
                  scrollableTarget="scrollable-container"
              >
                  <div className="product-list" id="scrollable-container">
                      {filteredProducts.map(product => (
                          <div key={uuidv4()} className="product">
                              <ProductBox product={product} />
                          </div>
                      ))}
                  </div>
              </InfiniteScroll>
          </div>
          )}

            <FeaturedProducts data={featuredProducts} />
           
           <Footer />
        </div>
    );
};

export default LandingPage;
