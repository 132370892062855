import React, { useState, useEffect } from 'react';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import './SignInLink.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';

function SignInLink() {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);
    const [recaptchaInstance, setRecaptchaInstance] = useState(null);

    useEffect(() => {
        const savedLang = localStorage.getItem('language');
        if (savedLang) {
            i18n.changeLanguage(savedLang);
            document.dir = savedLang === 'ar' ? 'rtl' : 'ltr';
        }
    }, [i18n]);

    // const handleCaptcha = async (token) => {
    //     setCaptchaToken(token);
    //     console.log('Captcha token:', token);

    //     // Proceed to send sign-in link after CAPTCHA is verified
    //     await sendSignInLink();
    // };

    const handleCaptcha = async (token) => {
        setCaptchaToken(token);
        console.log('Captcha token:', token);
        
        // Proceed to send sign-in link after CAPTCHA is verified
        if (token) {
            await sendSignInLink();
        } else {
            console.error('Captcha token is missing');
            alert("Captcha verification failed. Please try again.");
        }
    };

    const handleSendLink = async () => {
        if (!recaptchaInstance) {
            console.error('reCAPTCHA instance not ready');
            return;
        }

        // Trigger reCAPTCHA execution on form submit
        try {
            recaptchaInstance.execute();
        } catch (error) {
            console.error('Error executing reCAPTCHA:', error);
            // alert("Captcha execution failed. Please try again.");
        }
    };

    const sendSignInLink = async () => {
        const auth = getAuth();
        const actionCodeSettings = {
            url: `https://glamalertz.com/finishSignIn`,
            handleCodeInApp: true,
        };

        if (!captchaToken) {
            console.error('Captcha token is missing');
            alert("Captcha verification failed. Please try again.");
            return;
        }

        try {
            // Set persistence before sending sign-in link
            // await setPersistence(auth, browserLocalPersistence);

            // Verify CAPTCHA with your backend
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/verify-captcha`, {
                email,
                token: captchaToken,
            });

            if (response.data.success) {
                await sendSignInLinkToEmail(auth, email, actionCodeSettings);
                window.localStorage.setItem('emailForSignIn', email);
                alert(t('sendSigninLink.emailAlert'));
            } else {
                alert("Captcha verification failed. Please try again.");
            }
        } catch (error) {
            console.error('Error sending sign-in link:', error);
            alert(error.message);
        }
    };

    return (
        <div className="sign-in-container">
            <h1 className="title">{t('sendSigninLink.title')}</h1>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('sendSigninLink.searchbar')}
                className="input"
            />
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size="invisible"
                ref={(instance) => setRecaptchaInstance(instance)}  // Store the instance
                onChange={handleCaptcha}  // Callback after CAPTCHA is completed
            />
            <button onClick={handleSendLink} className="button">{t('sendSigninLink.button')}</button>
        </div>
    );
}

export default SignInLink;



