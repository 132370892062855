import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import SkincarePage from './components/SkincarePage';
import ProductPage from './components/ProductPage';
import MakeupPage from './components/MakeupPage';
import HaircarePage from './components/HaircarePage';
import SupplementsPage from './components/SupplementsPage';
import FragrancesPage from './components/FragrancesPage';
import SendSignInLinkPage from './components/SendSignInLinkPage';
import FinishSignInPage from './components/FinishSignInPage';
import NotificationsPage from './components/NotificationsPage';
import BackToTop from './components/BackToTop';
import MobilesPage from './components/MobilesPage';
import TabletsPage from './components/TabletsPage';
import SmartwatchesPage from './components/SmartwatchesPage';
import LaptopsPage from './components/LaptopsPage';
import TvsPage from './components/TvsPage';
import MonitorsPage from './components/MonitorsPage';
import About from './components/About';
import Terms from './components/Terms';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import ContactPage from './components/ContactPage';
import Iphone16Page from './components/Iphone16Page';

function App() {
    return (
    <div>
      <Router>
      <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/notifications" element={<NotificationsPage />} />

          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/contact" element={<ContactPage />} />

          <Route path="/iphone16" element={<Iphone16Page />} />
          <Route path="/skincare" element={<SkincarePage />} />
          <Route path="/product-page" element={<ProductPage />} />
          <Route path="/product-page/:id" element={<ProductPage />} />

          <Route path="/makeup" element={<MakeupPage />} />
          <Route path="/product-page/:id" element={<MakeupPage />} />

          <Route path="/haircare" element={<HaircarePage />} />
          <Route path="/supplements" element={<SupplementsPage />} />
          <Route path="/fragrances" element={<FragrancesPage />} />
          
          <Route path='/phones' element={<MobilesPage />} />
          <Route path='/tablets' element={<TabletsPage />} />
          <Route path='/smartwatches' element={<SmartwatchesPage />} />
          <Route path='/laptops' element={<LaptopsPage />} />
          <Route path='/tvs' element={<TvsPage />} />
          <Route path='/monitors' element={<MonitorsPage />} />

          <Route path="/sendSignInLink" element={<SendSignInLinkPage />} />
          <Route path="/finishSignIn" element={<FinishSignInPage />} />
      </Routes>
  </Router>

  <BackToTop /> 
  </div>
    );
}

export default App;
