import React from 'react';
import axios from 'axios';
import { capitalizeFirstLetterOfEachWord, formatPrice } from '../utils'; // Adjust the import path as needed
import { Link } from 'react-router-dom';
import './ProductBox.css';
import { getAuth } from 'firebase/auth'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function RadarBox({ product, onRemove }) {
  const { t, i18n } = useTranslation();

  const addInteraction = async () => {
    try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/user/interaction`, 
        { productId: product.productId, productLink: product.productLink },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
    } else {
       // interaction if not logged in
       await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/user/interaction/anon`, 
        { productId: product.productId, productLink: product.productLink },
      );
       }
      } catch (error) {
        console.error('Error adding interaction:', error);
      }
  }

  const handleRemove = () => {
    if (onRemove) {
      onRemove(product._id);
    }
  };
 

  return (
    <div className='product-box-container'>

    <Link onClick={addInteraction} className='product-page-link' to={`/product-page/${product.productId}`}>
     <div>
     <img 
         src={product.image} 
         alt={product.productName} 
         onError={(e) => { e.target.src = '/logo2.png'; console.error('Image not found:', product.productLink); }} 
     />
      <h2>{capitalizeFirstLetterOfEachWord(product.productName)}</h2>
     {/* <img 
         src={product.image} 
         alt={product.productName} 
         onError={(e) => { e.target.src = 'placeholder.jpg'; console.error('Image not found:', product.productLink); }} 
     /> */}
     <div>
         {product.prices.discounted ?
         <div>
          <p className='green'>{t('notificationsPage.alertSetAt')} {`${formatPrice(product.prices.discounted)} ${product.currency}`}</p>
          { product.notified ?
          <div className='alerted-container red'>
          <FontAwesomeIcon icon={faBell} className="icon" />
            <p>Alerted</p>
            </div> : null }
         {/* <span>Now: {product.prices.discounted}</span>
         <span>Was: {product.prices.original}</span> */}
         </div>
         : 
         <div>
        <p className='green'>{t('notificationsPage.alertSetAt')} {`${formatPrice(product.prices.original)} ${product.currency}`}</p>
        { product.notified ?
          <div className='alerted-container red'>
          <FontAwesomeIcon icon={faBell} className="icon" />
            <p>Alerted</p>
            </div> : null }
         {/* <span className='original-price original-price-only'>Now: {product.prices.original}</span> */}
         </div>
         }
     </div>
     <div className='store-name-container'>
         {/* <h3>Store: {product.store}</h3> */}
         <img 
         className='store-logo'
         src={product.storeLogo} 
         alt={product.store} 
         onError={(e) => { e.target.src = '/logo2.png'; console.error('Image not found:', product.productLink); }} 
     />
     </div>
  
     </div>
     </Link>
     <button className='remove-radar-button' onClick={handleRemove}><FontAwesomeIcon icon={faTrash} />  {t('notificationsPage.remove')}</button>
     </div>
  )
}
