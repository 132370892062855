import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackButton.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';

const BackButton = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="button-container"> {/* Center the button in this container */}
      <button className="back-button" onClick={handleBack}>
        <span className="icon"></span> {t('general.goBackButton')}
      </button>
    </div>
  );
};

export default BackButton;
