import React, { useEffect, useState, useCallback } from 'react';
import Header from './Header';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash/debounce';
import { useAuth } from '../AuthContext';
import RadarBox from './RadarBox';
import { v4 as uuidv4 } from 'uuid';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './NotificationsPage.css';
import BackButton from './BackButton';

export default function NotificationsPage() {
    const { t, i18n } = useTranslation();
    const { user } = useAuth(); // Access user from AuthContext

    const [radars, setRadars] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [message, setMessage] = useState("");


    useEffect(() => {
        if (!user) {
            setMessage(
                <p>
                    {t('notificationsPage.loginOrRegister')} <Link to="/sendSignInLink">{t('notificationsPage.loginOrRegister.link')}</Link> {t('notificationsPage.restOfLoginOrRegister')}
                </p>
            );
        } else {
            setMessage('');
        }
        console.log(message, "message")
    }, [user, i18n.language])

    const loader = user ? <p>{t('general.loading')}</p> : message;

    const fetchRadars = async (page, searchTerm = '') => {
        if (!user) {
        // setMessage(`Login or register ${<Link to="/sendSignInLink">here</Link>} to be able to add items to your price alert and see them here!`)
        return;
        }
        console.log(`Fetching alerts for page ${page} with searchTerm "${searchTerm}"`);
    
        try {
            const endpoint = searchTerm ? `${process.env.REACT_APP_API_BASE_URL}/api/user/radar` : `${process.env.REACT_APP_API_BASE_URL}/api/user/radars`;
            const idToken = await user.getIdToken();
    
            const response = await axios.get(endpoint, {
                params: { page, searchTerm },
                headers: { Authorization: `Bearer ${idToken}` }
            });
    
            const newRadars = response.data.products || [];
            // console.log('Fetched alerts:', newRadars);
    
            setRadars(prevRadars => {
                if (page === 1) {
                    // On a new search or first load, replace the existing radars
                    // console.log('Setting radars (page 1):', newRadars);
                    return newRadars;
                } else {
                    // For additional pages, merge with existing radars, ensuring uniqueness
                    const radarIdSet = new Set(prevRadars.map(radar => radar._id));
                    const uniqueNewRadars = newRadars.filter(radar => !radarIdSet.has(radar._id));
                    // console.log('Unique new radars:', uniqueNewRadars);
                    const updatedRadars = [...prevRadars, ...uniqueNewRadars];
                    // console.log('Setting radars (additional page):', updatedRadars);
                    return updatedRadars;
                }
            });
    
            setHasMore(newRadars.length > 0);
        } catch (error) {
            console.error('Error fetching alerts:', error);
        }
    };
    

    const debouncedFetchRadars = useCallback(debounce((page, searchTerm) => {
        fetchRadars(page, searchTerm);
    }, 500), []);


    useEffect(() => {
        if (user) {
          if (search.trim() === '') {
            setPage(1);
            setRadars([]);
            fetchRadars(1);
          } else {
            setPage(1);
            setHasMore(true);
            debouncedFetchRadars(1, search);
          }
        } 
        
        // else {
        //   setLoading(true); // Ensure loading state when user is not authenticated
        // }
      }, [search, debouncedFetchRadars, user]);



    useEffect(() => {
        if (page > 1 && user) {
          fetchRadars(page, search);
        }
      }, [page, search, user]);
    

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleRemoveRadar = async (productId) => {
        try {
            if (user) {
                const idToken = await user.getIdToken();
                await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/user/radar`, 
                  { productId },
                  { headers: { Authorization: `Bearer ${idToken}` } }
                );
                setRadars(prevRadars => prevRadars.filter(product => product._id !== productId));
            }
        } catch (error) {
            console.error('Error removing alert:', error);
        }
    };

    return (
        <div className="container">
            <div className='header-menu-container'>
                <Header />
                {/* <Menu /> */}
            </div>
            <BackButton />
            <div className="search-bar">
                <input 
                    type="text" 
                    placeholder={t('notificationsPage.searchbar')}
                    value={search} 
                    onChange={handleSearchChange} 
                />
            </div>
            <div className="skincare-products">
                <h1>{t('notificationsPage.title')}</h1>
                <InfiniteScroll
                    dataLength={radars.length}
                    next={() => setPage(prevPage => prevPage + 1)}
                    hasMore={hasMore}
                    // loader={`${user ? <p>Loading...</p> : message ? message : "" }`}
                    loader={loader}
                    endMessage={<p>{t('notificationsPage.noMoreAlerts.p')}</p>}
                >
                    <div className="product-list">
                        {radars.map(product => (
                            <div key={uuidv4()} className='product'>
                                <RadarBox product={product} onRemove={handleRemoveRadar} />
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
            <Footer />
        </div>
    );
}