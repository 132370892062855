import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';
import './Terms.css';

export default function Terms() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
      const savedLang = localStorage.getItem('language');
      console.log("use Effecting header")
      if (savedLang) {
          i18n.changeLanguage(savedLang);
          document.dir = savedLang === 'ar' ? 'rtl' : 'ltr';
      }
  }, [i18n]);

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        <Header />
        <main>
            <h1>{t('termsPage.title')}</h1>
           <p>{t('termsPage.intro')}</p>
           <ul>
            <li>
            <h3>{t('termsPage.termsList.acceptanceOfTerms.title')}</h3> 
            {t('termsPage.termsList.acceptanceOfTerms.description')}
            </li>
            <li>
            <h3>{t('termsPage.termsList.useOfServices.title')}</h3> 
            {t('termsPage.termsList.useOfServices.description')}
            </li>
            <li>
            <h3>{t('termsPage.termsList.userAccounts.title')}</h3> 
            {t('termsPage.termsList.userAccounts.description')}
            </li>
            <li>
            <h3>{t('termsPage.termsList.dataCollection.title')}</h3> 
            {t('termsPage.termsList.dataCollection.description')}
            </li>
            <li>
            <h3>{t('termsPage.termsList.thirdPartyLinks.title')}</h3> 
            {t('termsPage.termsList.thirdPartyLinks.description')}
            </li>
            <li>
            <h3>{t('termsPage.termsList.limitationOfLiability.title')}</h3> 
            {t('termsPage.termsList.limitationOfLiability.description')}
            </li>
            <li>
            <h3>{t('termsPage.termsList.changesToTerms.title')}</h3> 
            {t('termsPage.termsList.changesToTerms.description')}
            </li>
           </ul>
        </main>
        <Footer />
    </div>
  );
}