import React from 'react';
import './SendSignInLinkPage.css';
import SignInLink from './SignInLink';
import Header from './Header';
import Footer from './Footer';
import BackButton from './BackButton';

const SendSignInLinkPage = () => {
    return (
        <div className="container">
        <Header />
        <BackButton />
         <SignInLink />
         <Footer />
        </div>
    );
};

export default SendSignInLinkPage;
