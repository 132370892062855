import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import './ContactPage.css';
const ContactPage = () => {
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className='container'>
             <Helmet>
            <html lang={i18n.language} />
            <title>
                {i18n.language === 'ar' ? 'اتصل بنا | PriceAlertz' : 'Contact Us | PriceAlertz'}
            </title>
            <meta
                name="description"
                content={
                    i18n.language === 'ar'
                        ? 'تواصل معنا لأية استفسارات أو ملاحظات. نحن هنا للمساعدة.'
                        : 'Contact us for any inquiries or feedback. We are here to assist you.'
                }
            />
            <meta
                name="keywords"
                content={
                    i18n.language === 'ar'
                        ? 'اتصل، الدعم، استفسارات، مساعدة، PriceAlertz'
                        : 'contact, support, inquiries, help, PriceAlertz'
                }
            />
        </Helmet>
        <Header />
        <div className="contact-container">
           
            <h1 className="title">{t('contactUs.title')}</h1>
            <form 
                name="contact" 
                method="POST" 
                data-netlify="true" 
                netlify-honeypot="bot-field"
                netlify
            >
                 {/* Hidden input for Netlify  */}
                <input type="hidden" name="form-name" value="contact" />
                <p style={{ display: 'none' }}>
                    <label>Don’t fill this out: <input name="bot-field" /></label>
                </p>

                <input 
                    type="text" 
                    name="name" 
                    value={formData.name} 
                    onChange={handleInputChange} 
                    placeholder={t('contactUs.namePlaceholder')} 
                    className="input"
                    required 
                />
                
                <input 
                    type="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleInputChange} 
                    placeholder={t('contactUs.emailPlaceholder')} 
                    className="input"
                    required 
                />

                <input 
                    type="text" 
                    name="subject" 
                    value={formData.subject} 
                    onChange={handleInputChange} 
                    placeholder={t('contactUs.subjectPlaceholder')} 
                    className="input"
                    required 
                />

                <textarea 
                    name="message" 
                    value={formData.message} 
                    onChange={handleInputChange} 
                    placeholder={t('contactUs.messagePlaceholder')} 
                    className="input textarea"
                    required 
                ></textarea>

                <button type="submit" className="button">
                    {t('contactUs.submitButton')}
                </button>
            </form>
          </div>
          <Footer />
        </div>
    );
};

export default ContactPage;


