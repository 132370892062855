import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddToRadar.css';
import { getAuth } from 'firebase/auth'; // Import Firebase Auth functions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const AddToRadar = ({ product }) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

      const addToRadar = async () => {
        try {
          const auth = getAuth();
          const user = auth.currentUser;
          
          if (user) {
            const idToken = await user.getIdToken();
    
              await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/user/radar`, 
              { productId: product._id, productLink: product.link, prices: product.prices, currency: product.currency, productName: product.name, image: product.image, store: product.store, source: product.source, storeLogo: product.storeLogo },
              { headers: { Authorization: `Bearer ${idToken}` } }
            );

               // Update local storage with added product _id
            // const radarItems = JSON.parse(localStorage.getItem('radarItems')) || [];
            // radarItems.push(product._id);
            // localStorage.setItem('radarItems', JSON.stringify(radarItems));
          if(i18n.language === "en") {
              setMessage('Product added to your alerts successfully.');
          }  else if (i18n.language === "ar") {
            setMessage('تمت إضافة المنتج إلى التنبيهات الخاصة بك بنجاح.');
          }  else {
            setMessage('Product added to your alerts successfully.');
          }
            // setAddedToRadar(true);
            setIsLoading(false);
    
          //  alert('Product added to your radar. You will be notified when there are offers!');
          } else {
         //  alert('Please log in to add product to your radar.');


         if(i18n.language === "en") {
          setMessage('Please log in to add product to your radar.');
        }  else if (i18n.language === "ar") {
          setMessage('يرجى تسجيل الدخول لإضافة المنتج إلى تنبيهات الأسعار الخاصة بك.');
        }  else {
          setMessage('Please log in to add product to your radar.');
        }
         

            // setMessage(<SignInLink productId={product._id} emailSent={emailSent} setEmailSent={setEmailSent} />);

            // setAddedToRadar(false);
            setIsLoading(false);
            // return <SendSignInLinkPage />
            
          }
        } catch (error) {
          console.error('Error adding product to radar:', error);
          if (error.response && error.response.data) {
            if(error.response.data.includes("Item already on your radar!")) {
              if(i18n.language === "en") {
                alert('Item already on your radar!');
              }  else if (i18n.language === "ar") {
                alert('تمت إضافة المنتج إلى إشعارات الأسعار الخاصة بك من قبل.')
              }  else {
                alert('Item already on your radar!');
              }
            }
             else {
              alert(error.response.data);
             }
          } else {
            alert('An error occurred while adding the product to your radar.');
          }

          setIsLoading(false);
        }
      };

      useEffect(() => {
        return () => {
          // Clear the message when the component unmounts
          setMessage('');
        };
      }, [product]);


  return (
    <div className='add-to-radar-login-container'>
    <div className="add-to-radar-container">
      <button
        className="add-to-radar-button"
        onClick={addToRadar}
        disabled={isLoading}
      >
        <FontAwesomeIcon icon={faBell} className="icon" />
        {/* {isLoading ? 'Adding...' : addedToRadar ? 'Added to Radar' : 'Add to Radar'} */}
        {isLoading ? t('general.adding') : t('productPage.addToRadar.alertForBetterPrice')}
      </button>
      {/* <p>Get email notification when prices drop.</p> */}
      {isLoading && <div className="loading-spinner">{t('general.loading')}</div>}
      {message && <div className={`message ${message.includes('Please') ? 'error' : message.includes('يرجى') ? 'error' : 'success'}`}>{message}</div>}
   
    </div>
       {/* {message && 
      <div className='add-email-radar success'>
      <div>{message}</div>
       </div>
       } */}
    </div>
  );
};

export default AddToRadar;



