import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faPaintBrush, faCut, faFlask, faCapsules, faMobileAlt, faLaptop, faClock, faDesktop, faTv, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import './MenuModal.css';
import { useTranslation } from 'react-i18next';
const MenuModal = ({ onClose }) => {
    const { t, i18n } = useTranslation();
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="modal-overlay">
            <div className="modal-content" ref={modalRef}>
                <button className="close-button" onClick={onClose}>×</button>
                <h2>{t('menuModal.categories.title')}</h2>
                <div className="modal-category-list">
                    <Link to="/skincare" className="modal-category">
                        <FontAwesomeIcon icon={faSmile} className="icon" /> {t('menuModal.categories.skincare')}
                    </Link>
                    <Link to="/makeup" className="modal-category">
                        <FontAwesomeIcon icon={faPaintBrush} className="icon" /> {t('menuModal.categories.makeup')}
                    </Link>
                    <Link to="/haircare" className="modal-category">
                        <FontAwesomeIcon icon={faCut} className="icon" /> {t('menuModal.categories.haircare')}
                    </Link>
                    <Link to="/fragrances" className="modal-category">
                        <FontAwesomeIcon icon={faFlask} className="icon" /> {t('menuModal.categories.fragrances')}
                    </Link>
                    <Link to="/supplements" className="modal-category">
                        <FontAwesomeIcon icon={faCapsules} className="icon" /> {t('menuModal.categories.supplements')}
                    </Link>
                    <Link to="/phones" className="modal-category">
                        <FontAwesomeIcon icon={faMobileAlt} className="icon" /> {t('menuModal.categories.phones')}
                    </Link>
                    <Link to="/smartwatches" className="modal-category">
                        <FontAwesomeIcon icon={faClock} className="icon" /> {t('menuModal.categories.smartwatches')}
                    </Link>
                    <Link to="/tablets" className="modal-category">
                        <FontAwesomeIcon icon={faTabletAlt} className="icon" /> {t('menuModal.categories.tablets')}
                    </Link>
                    <Link to="/laptops" className="modal-category">
                        <FontAwesomeIcon icon={faLaptop} className="icon" /> {t('menuModal.categories.laptops')}
                    </Link>
                    <Link to="/tvs" className="modal-category">
                        <FontAwesomeIcon icon={faTv} className="icon" /> {t('menuModal.categories.tvs')}
                    </Link>
                    <Link to="/monitors" className="modal-category">
                        <FontAwesomeIcon icon={faDesktop} className="icon" /> {t('menuModal.categories.monitors')}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default MenuModal;
