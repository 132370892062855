// utils.js
export const capitalizeFirstLetterOfEachWord = (str) => {
    return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

export const formatPrice = (price) => {
    if (typeof price !== 'string') {
      return price;
    }
  
    // Remove any non-numeric characters except the decimal point
    const numericValue = price.replace(/[^\d.]/g, '');
  
    // Format the number with commas as thousand separators
    return parseFloat(numericValue).toLocaleString('en-US');
  }
  
